import React, { useEffect, useContext } from "react";
import UserContext from "../../Providers/UserContext.js";
import axios from 'axios'
import styled from 'styled-components';

export function Instagram() {

    const { instagramImages, setInstagramImages } = useContext(UserContext);

    const url = "https://graph.instagram.com/me/media?access_token=" + process.env.REACT_APP_TOKENBRUNA + "&fields=media_url,media_type,caption,permalink";

    useEffect(() => {
        try{
            const promise = axios.get(url);
            promise.then(response => {
                if(response.data){
                    let array = [];
                    for(let i = 0; i<6;i++){
                        if(response.data.data[i]){
                            array.push(response.data.data[i]);
                        }
                    }
                    setInstagramImages(array);
                }
            });
        }
        catch(e){
            alert('Falha.');
        }
    }, []);

    function InstagramFeed(){
        if (instagramImages.length > 0){
            let cont = -1;
            return (
                instagramImages.map(pub => {
                    if(pub.media_type != "VIDEO"){
                        cont++;
                        return(
                            <a key = {cont} href = {pub.permalink}><img loading = "lazy" src = {pub.media_url}/></a>
                        )
                    }
                    
                })
            )
        }
        else{
            return null;
        }
    }

    return (
        <InstagramBackground className = "responsiveInstagram change notVisible session">
            <InstagramContent className = "responsiveInstagramContent container">
                <Part7Text className = "responsiveInstagramText">
                    <h2>
                        Siga-me no instagram!
                    </h2>
                    <p>
                        Fique por dentro de dicas e conteúdos novos
                    </p>
                    <br/>
                    <a href = "https://www.instagram.com/psi.brunavianna/" className= "buttonBody">
                        <p>
                            @psi.brunavianna
                        </p>
                    </a>
                </Part7Text>
                <InstagramFeedImages className = "responsiveInstagramFeedImages">
                    <InstagramFeedBackground className = "responsiveInstagramFeedBackground" />
                    <InstagramImages className = "responsiveInstagramImages">
                        <InstagramFeed/>
                    </InstagramImages>
                </InstagramFeedImages>
            </InstagramContent>
        </InstagramBackground>
    )
};

const InstagramBackground = styled.div`
    background-color: #F0D6FF;
    padding: 30px 0px;
    h2{
        font-weight: bold;
    }
    height: 300px;
    display: flex;
    margin: 195px 0px;
`;

const InstagramContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    text-align: center;
`;

const Part7Text = styled.div`
    width: 50%;
`;

const InstagramFeedImages = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 1px solid black;
    padding-left: 50px;
    img{
        width: 160px;
        height: auto;
    }
    width: 52%
`;

const InstagramFeedBackground = styled.div`
    background-color: #B191C3;
    width: 400px;
    height: 500px;
    margin-bottom: -490px;
`;

const InstagramImages = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 30px 0px;
    height: 484px;
`;