import React from 'react';
import styled from 'styled-components';
import { useJsApiLoader } from '@react-google-maps/api';
import { Logo } from '../../Imgs/Logo.js';

export function Footer() {

    return (
        <Section id = "contato" className = "session">
            <Content className = "container">
                <Contact className = "change notVisible">
                    <h2>ENTRE EM CONTATO</h2>
                    <br/>
                    <br/>
                    <ContactDiv>
                        <i className = "fa fa-envelope" aria-hidden="true"></i>
                        <p>
                            Email: psibrunavianna@gmail.com
                        </p>
                    </ContactDiv>
                    <ContactDiv>
                        <i className = "fa fa-phone-square" aria-hidden="true"></i>
                        <p>
                            Cel.: (21) 97274-8304
                        </p>
                    </ContactDiv>
                    <ContactDiv>
                        <i className = "fa fa-map-marker" aria-hidden="true"></i>
                        <p>
                            Endereço: Avenida das Américas, nº 15015
                        </p>
                    </ContactDiv>
                </Contact>
                <iframe className = "change notVisible"
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJR5RoyTDdmwARTws1YBHGQC0&key=AIzaSyCxR9Wxn4-VOmRiOu3bJhlPH4nOp0zNbx4">
                </iframe>
            </Content>
            <LogoText className = "change notVisible container">
                <ImgTextIcon>
                    <Logo/>
                    <TextIcon>
                        <h3>
                            Bruna Vianna
                        </h3>
                        <Icons>
                            <Icon href = "https://wa.me/5521972748304?text=Ol%C3%A1!%20Gostaria%20de%20marcar%20uma%20consulta." className = "buttonFooterFa">
                                <i className = "footerFa fa fa-whatsapp" aria-hidden = "true"></i>
                            </Icon>
                            <Icon href = "https://www.instagram.com/psi.brunavianna/?igshid=MzRlODBiNWFlZA%3D%3D" className = "buttonFooterFa">
                            <i className = "footerFa fa fa-instagram" aria-hidden = "true"></i>
                            </Icon>
                        </Icons>
                    </TextIcon>
                </ImgTextIcon>
                <a href = "https://wa.me/5521972748304?text=Ol%C3%A1!%20Gostaria%20de%20marcar%20uma%20consulta." className= "buttonFooter">
                    <h3>
                        AGENDE JÁ A SUA SESSÃO
                    </h3>
                </a>
            </LogoText>
            <Sep/>
            <CopyRight className = "change notVisible container">
                <h3>
                    Copyright © 2023 | Bruna Vianna - CRP 05/68382
                </h3>
            </CopyRight>
        </Section>
    );
}

const Section = styled.div`
    background-color: #57529F;
    padding: 30px 0px 10px 0px;
    a{
        text-align: center;
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    iframe{
        width: 55%;
    }
`;

const ImgTextIcon = styled.div`
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        width: auto;
        height: 60px;
    }
    h3{
        color: white;
        margin-bottom: 3px;
    }
`;

const TextIcon = styled.div`
    width: 81px;
    text-align: center;
`;

const Icons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Icon = styled.a`
    background-color: white;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Contact = styled.div`
    width: 40%;
    h2{
        color: white;
    }
    p{
        color: white;
    }
`;

const ContactDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    color: white;
    margin-bottom: 15px;
`;

const LogoText = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
`;

const Sep = styled.div`
    width: 100%;
    height: 1px;
    background-color: white;
    margin: 15px 0px;
`;

const CopyRight = styled.div`
    h3{
        color: white;
    }
`;