import React from 'react';
import styled from 'styled-components';

export function Part6() {

    function openQuestion(n) {

        let content = document.querySelectorAll(".part6DivContent");
        let questions = document.querySelectorAll(".part6Div");

        for(let i = 0; i<content.length; i++){
            if(i !== n){
                if(!content[i].classList.contains("hidden")){
                    questions[i].classList.toggle("buttonFAQ");
                    questions[i].children[1].classList.toggle("part6Fa");
                    content[i].classList.toggle("hidden");
                }
            }
        }

        questions[n].classList.toggle("buttonFAQ");
        questions[n].children[1].classList.toggle("part6Fa");
        content[n].classList.toggle("hidden");

    }

    return (
        <div className = "container session change notVisible">
            <Part6Title>
                <h2>
                    Fatos e Perguntas
                </h2>
                <br/>
                <p>
                    Dúvidas e esclarecimentos que possam ajudar
                </p>
            </Part6Title>
            <Part6Divs>
                <Part6Div className = "part6Div session" onClick = {() => openQuestion(0)}>
                    <p>
                        Qual o valor da sessão?
                    </p>
                    <i className = "fa fa-angle-down" aria-hidden="true"></i>
                </Part6Div>
                <Part6DivContent className = "part6DivContent hidden">
                    <p>
                        O Conselho Federal de Psicologia não permite a divulgação de valores, por isso entre em contato através do whatsapp para maiores informações.
                    </p>
                </Part6DivContent>
                <Part6Div className = "part6Div session" onClick = {() => openQuestion(1)}>
                    <p>
                        Aceita convênio médico?
                    </p>
                    <i className = "fa fa-angle-down" aria-hidden="true"></i>
                </Part6Div>
                <Part6DivContent className = "part6DivContent hidden">
                    <p>
                        Não, porém, eu emito recibo para que você consiga solicitar um reembolso através do seu plano de saúde.
                    </p>
                </Part6DivContent>
                <Part6Div className = "part6Div session" onClick = {() => openQuestion(2)}>
                    <p>
                        Qual a duração da sessão?
                    </p>
                    <i className = "fa fa-angle-down" aria-hidden="true"></i>
                </Part6Div>
                <Part6DivContent className = "part6DivContent hidden">
                    <p>
                        Dura em média 50 minutos.
                    </p>
                </Part6DivContent>
            </Part6Divs>
        </div>
    )
};

const Part6Title = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 50px;
`;

const Part6Divs = styled.div`
    margin-top: 30px;
`;

const Part6Div = styled.button`
    margin-top: 20px;
    background-color: white;
    width: 100%;
    border-bottom: 1px solid #57529F;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
        color: #57529F;
        font-weight: bold;
    }
    transition-timing-function: ease-in;
    transition: color 0.2s, background-color 0.2s;
`;

const Part6DivContent = styled.div`
    background-color: #57529F;
    padding: 30px;
    p{
        color: white;
    }
`;