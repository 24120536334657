import React from 'react';
import styled from 'styled-components';
import { HashLink } from "react-router-hash-link"
import { Logo } from '../../Imgs/Logo.js';

export function Header() {

    const fixedNavbar = () => {
        let navbar = document.querySelector(".navbar");
        if(window.scrollY >= 20){
            navbar.classList.add("navbarFixed");
        }
        else{
            navbar.classList.remove("navbarFixed");
        }
    }
    window.addEventListener('scroll', fixedNavbar);

    function responsiveNavigate(){
        document.querySelector(".sandwichMenu").classList.toggle("hidden");
        document.querySelector("body").classList.toggle("noOverflow");
        document.querySelector(".menuS").classList.toggle("change");
    }

    function clickSandwich() {
        document.querySelector(".menuS").classList.toggle("change");
        document.querySelector(".sandwichMenu").classList.toggle("hidden");
        document.querySelector("body").classList.toggle("noOverflow");
    }

    return (
        <Content className = "navbar">
            <NavBar className = "responsiveNavbar container">
                <ul>
                    <li><HashLink to = "/#inicio" className = "buttonNav" smooth><p>Página Inicial</p></HashLink></li>
                    <li><HashLink to = "/#quemSou" className = "buttonNav" smooth><p>Quem Sou</p></HashLink></li>
                    <li><HashLink to = "/#abordagem" className = "buttonNav" smooth><p>Abordagem</p></HashLink></li>
                </ul>
                <Logo/>
                <ul>
                    <li><HashLink to = "/#atendimentos" className = "buttonNav" smooth><p>Atendimentos</p></HashLink></li>
                    <li><HashLink to = "/#faq" className = "buttonNav" smooth><p>FAQ</p></HashLink></li>
                    <li><HashLink to = "/#contato" className = "buttonNav" smooth><p>Contato</p></HashLink></li>
                </ul>
            </NavBar>
            <div className = "responsiveSandwich container">
                <button className = "menuS" onClick = {clickSandwich}>
                    <div className = "menu bar1"></div>
                    <div className = "menu bar2"></div>
                    <div className = "menu bar3"></div>
                </button>
                <Logo/>
                <SandwichMenu className = "sandwichMenu hidden">
                    <HashLink to = "/#inicio" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Página Inicial</p></HashLink>
                    <HashLink to = "/#quemSou" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Quem Sou</p></HashLink>
                    <HashLink to = "/#abordagem" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Abordagem</p></HashLink>
                    <HashLink to = "/#atendimentos" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Atendimentos</p></HashLink>
                    <HashLink to = "/#faq" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>FAQ</p></HashLink>
                    <HashLink to = "/#contato" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Contato</p></HashLink>
                </SandwichMenu>
            </div>
        </Content>
    );
}

const Content = styled.section`
    top: 0px;
    position: absolute;
    width: 100%;
    background-color: #F0D6FF;
    padding: 10px 0px;
    transition: color 0.2s, position 0.2s, box-shadow 0.2s, height 0.2s;
    transition-timing-function: ease-in;
    img{
        width: auto;
        height: 70px;
        transition: height 0.2s;
    }
`;

const NavBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 150px;
    ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        width: 100%;
    }
    li{
        display: inline-block;
    }
`;

const SandwichMenu = styled.div`
    z-index: 1;
    background-color: white;
    position: fixed;
    left: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 50px 10px;
    transition: all 0.3s ease-in-out 0.1s;
`;