import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { ImgBruna } from '../../Imgs/fotoBruna.js';

export function Part1() {

    const navigate = useNavigate();

    return (
        <Content className = "change notVisible responsivePart1Content" id = "inicio">
            <Part1ImgText className = "responsivePart1ImgText container2">
                <Part1Img className = "responsiveImg backgroundImage homePagePart1BackgroundImage"></Part1Img>
                <Part1Text className = "responsivePart1Text">
                    <h1>
                        Psicóloga Bruna Vianna
                    </h1>
                    <br/>
                    <h3>
                        Psicóloga clínica CRP 05/68382
                    </h3>
                    <div className = "sepH"></div>
                    <br/>
                    <p>
                        <strong>"A mente é como um paraquedas: só funciona se estiver aberto"</strong>
                    </p>
                    <p>
                        - Albert Einstein
                    </p>
                    <br/>
                    <p>
                        Não espere pelo amanhã o que está ao seu alcance para realizar hoje:
                    </p>
                    <br/>
                    <a href = "https://wa.me/5521972748304?text=Ol%C3%A1!%20Gostaria%20de%20marcar%20uma%20consulta." className= "buttonBody">
                        <p>
                            AGENDE JÁ A SUA SESSÃO
                        </p>
                    </a>
                </Part1Text>
            </Part1ImgText>
        </Content>
    )
};

const Content = styled.div`
    padding-top: 100px;
    background-color: #F0D6FF;
`;

const Part1Img = styled.div`
    width: 45%;
    height: 600px;
    margin-bottom: -50px;
`;

const Part1ImgText = styled.div`
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

const Part1Text = styled.div`
    width: 45%;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;