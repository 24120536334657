import React from 'react';
import styled from 'styled-components';
import { ImgBruna } from '../../Imgs/fotoBruna.js';

export function Part3() {

    return (
        <Content id = "quemSou" className = "responsiveContent container session">
            <Part3Div1 className = "responsivePart3Div1"/>
            <Part3Img className = "change notVisible responsiveImg backgroundImage homePagePart3BackgroundImage"></Part3Img>
            <Part3Text className = "responsivePart3Text change notVisible">
                    <h2>
                        Quem sou
                    </h2>
                    <br/>
                    <p>
                        Olá, muito prazer! Sou a Bruna, psicóloga clínica formada pela Pontifícia Universidade Católica do Rio de Janeiro (PUC-RIO), especialista em terapia cognitivo comportamental 
                        e nutro uma paixão profunda pelas histórias e interpretações da vida. 
                    </p>
                    <br/>
                    <p>
                        Minha missão consiste em auxiliar indivíduos a alcançarem uma vida mais plena e saudável. Por meio da abordagem da Terapia Cognitivo-Comportamental, nosso percurso vai 
                        envolver a identificação de padrões de pensamento e comportamento disfuncionais que impactam sua jornada.
                    </p>
                    <br/>
                    <p>
                        Empregaremos técnicas terapêuticas respaldadas em evidências para instigar transformações positivas, cultivando uma percepção mais saudável de si mesmo com o intuito de 
                        alcançar um estado emocional mais equilibrado. Desejo ser um guia na superação das dificuldades que você possa estar enfrentando, auxiliando-o a construir a vida que 
                        almeja.
                    </p>
                    <br/>
                    <p>
                    Minha experiência abrange temas como ansiedade, depressão, transtorno de personalidade borderline, transtorno afetivo bipolar, distúrbios alimentares, autoestima, 
                    relacionamentos, transtorno do estresse pós traumático, dependências e outras áreas que frequentemente se interconectam. Lembre-se: a felicidade é um direito seu! 
                    </p>
            </Part3Text>
            <Div id = "abordagem"/>
        </Content>
    )
};

const Content = styled.div`
    img{
        float: right;
        width: 60%;
        height: 500px;
        margin: -60px 30px 0px 0px;
    }
    margin-top: 140px;
`;

const Part3Img = styled.div`
    float: right;
    width: 60%;
    height: 700px;
    margin: -60px 30px 0px 0px;
`;

const Part3Div1 = styled.div`
    background-color: #B191C3;
    height: 100px;
    width: 80%;
    float: right;
`;

const Part3Text = styled.div`
    background-color: #F0D6FF;
    padding: 30px;
    width: 48%;
    float: left;
    margin-top: -590px;
    height: 540px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Div = styled.div`
    float: right;
`;