import React from 'react';
import styled from 'styled-components';
import { ImgBruna } from '../../Imgs/fotoBruna.js';

export function Part4() {

    return (
        <Part4Content className = "responsivePart4Content">
            <Part4Background className = "responsivePart4Background" />
            <Part4Block1 className = "responsivePart4">
                <Part4Div1Block1 className = "responsivePart4Div1"/>
                <Content className = "container">
                    <Part4Img className = "change notVisible responsiveImg backgroundImage homePagePart4BackgroundImage1"></Part4Img>
                    <Part4Text className = "responsivePart4Text change notVisible">
                        <h2>
                            Minha Abordagem
                        </h2>
                        <br/>
                        <p>
                            A terapia cognitivo-comportamental é uma abordagem psicoterapêutica que consiste em ajudar as pessoas a identificarem 
                            padrões de comportamento e pensamento disfuncionais.
                        </p>
                        <br/>
                        <p>
                            Um dos seus principais princípios consiste que o nos afeta não 
                            são as situações em si, e sim como a gente as interpreta, ou seja, a forma como a pessoa vê, sente e pensa sobre determinada situação.
                        </p>
                        <br/>
                        <p>
                            O tratamento consiste em um forte foco nas habilidades que precisam ter para melhorarem seu humor e consequentemente a sua vida. 
                            Vamos trabalhar de forma colaborativa para identificar metas terapêuticas específicas e elaborar um plano de ação.
                        </p>
                        <br/>
                        <p>
                            A TCC (terapia cognitivo comportamental) tem uma ampla evidência científica na eficácia da maioria dos transtornos psicológicos, 
                            sendo muitas vezes, considerada padrão ouro de tratamento.
                        </p>
                        <br/>
                        <a id = "atendimentos" href = "https://wa.me/5521972748304?text=Ol%C3%A1!%20Gostaria%20de%20marcar%20uma%20consulta." className= "buttonBody">
                            <p>
                                AGENDE JÁ A SUA SESSÃO
                            </p>
                        </a>
                    </Part4Text>
                </Content>
            </Part4Block1>
            <Part4Block2 className = "responsivePart4">
                <Part4Div1Block2 className = "responsivePart4Div1"/>
                <Content className = "container">
                    <Part4Text className = "responsivePart4Text change notVisible">
                        <h2>
                            Meus Atendimentos
                        </h2>
                        <br/>
                        <p>
                            Ofereço um ambiente individualizado voltado para o acolhimento e cuidado, onde você pode compartilhar suas angústias e sofrimento. 
                        </p>
                        <br/>
                        <p>
                            Minha abordagem terapêutica se baseia em metas personalizadas para cada situação, embora o aspecto mais crucial seja garantir 
                            sua sensação de acolhimento e segurança. Vale ressaltar que a terapia não opera como magia; portanto, sua dedicação e comprometimento 
                            são essenciais para nossa jornada conjunta em direção aos objetivos alcançados.
                        </p>
                        <br/>
                        <p>
                            Sempre, recomendo sessões semanais, com a flexibilidade de ajustar a frequência conforme sua possibilidade. As sessões podem ser conduzidas 
                            online ou presencialmente, se você estiver localizado no Rio de Janeiro - RJ.
                        </p>
                        <br/>
                        <a href = "https://wa.me/5521972748304?text=Ol%C3%A1!%20Gostaria%20de%20marcar%20uma%20consulta." className= "buttonBody">
                            <p>
                                AGENDE JÁ A SUA SESSÃO
                            </p>
                        </a>
                    </Part4Text>
                    <Part4Img className = "change notVisible responsiveImg backgroundImage homePagePart4BackgroundImage2"></Part4Img>
                </Content>
            </Part4Block2>
            <Part4Block3 className = "responsivePart4">
                <Part4Div1Block1 className = "responsivePart4Div1"/>
                <Content className = "container">
                    <Part4Img className = "change notVisible responsiveImg backgroundImage homePagePart4BackgroundImage3"></Part4Img>
                    <Part4Text className = "responsivePart4Text change notVisible">
                        <h2>
                            Áreas de Foco
                        </h2>
                        <br/>
                        <p>
                            As minhas principais áreas de foco são: ansiedade, transtorno de humor (depressão, bipolar), transtornos de personalidade (borderline, dependente, narcisista), 
                            autoconhecimento, transtorno do estresse pós traumático, relacionamentos, síndrome de burnout e regulação emocional.
                        </p>
                        <br/>
                        <a id = "faq" href = "https://wa.me/5521972748304?text=Ol%C3%A1!%20Gostaria%20de%20marcar%20uma%20consulta." className= "buttonBody">
                            <p>
                                AGENDE JÁ A SUA SESSÃO
                            </p>
                        </a>
                    </Part4Text>
                </Content>
            </Part4Block3>
        </Part4Content>
    )
};

const Part4Content = styled.div`
    padding-top: 860px;
    transition: all 0.2s;
`;

const Part4Background = styled.div`
    width: 50%;
    border-right: 1px solid black;
    height: 1980px;
    margin-bottom: -2715px;
`;

const Part4Img = styled.div`
    height: 500px;
    width: 55%;
`;

const Content = styled.div`
    a{
        display: inline-block;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
`;

const Part4Block1 = styled.div`
    margin-top: 740px;
`;

const Part4Block2 = styled.div`
    margin-top: 130px;
`;

const Part4Block3 = styled.div`
    margin-top: 130px;
`;

const Part4Div1Block1 = styled.div`
    background-color: #B191C3;
    height: 600px;
    width: 30%;
    
    margin-bottom: -550px;
`;

const Part4Div1Block2 = styled.div`
    background-color: #B191C3;
    height: 600px;
    width: 30%;
    float: right;
    margin-bottom: -550px;
`;

const Part4Text = styled.div`
    width: 40%;
`;