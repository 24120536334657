import React, { useEffect } from 'react';
import { Header } from '../../components/Header/Header.js';
import { Instagram } from '../../components/Instagram/Instagram.js';
import { Footer } from '../../components/Footer/Footer.js';
import { Part1 } from './Part1.js';
import { Part3 } from './Part3.js';
import { Part4 } from './Part4.js';
import { Part6 } from './Part6.js';

export default function HomePage() {

    const activeScroll = () => {
        document.querySelectorAll('.change').forEach((div, index) => {
            if(!div.classList.contains("noChange")){
                if(div.getBoundingClientRect().top < window.innerHeight) {
                    div.classList.remove("notVisible");
                }
            }
        })
    }
    window.addEventListener('scroll', activeScroll);

    useEffect(() => {
        document.querySelectorAll('.change').forEach((div, index) => {
            if(!div.classList.contains("noChange")){
                if(div.getBoundingClientRect().top < window.innerHeight) {
                    div.classList.remove("notVisible");
                }
            }
        })
      }, [])

    return (
        <>
            <Header/>
            <Part1/>
            <Part3/>
            <Part4/>
            <Part6/>
            <Instagram/>
            <Footer/>
        </>
    )
};
